<template>
    <div class="conversations-container">
        <!-- Loader -->
        <div id="loading" v-if="isLoading">Cargando...</div>

        <!-- Mostrar el modal solo si isModalVisible es true -->
        <div v-if="isModalVisible" class="modal-background">
            <send-template-window @close="isModalVisible = false" />
        </div>

        <!-- Top Bar -->
        <div class="top-bar">
            <!-- Botón Nuevo Chat -->
            <button class="new-chat-btn" :class="{ active: isModalVisible }" @click="openNewChat">Nuevo
                Chat</button>

            <!-- Menú desplegable -->
            <div class="menu">
                <button class="menu-button" @click="toggleMenu">☰</button>
                <div v-if="menuOpen" class="menu-dropdown">
                    <button @click="refreshConversations">Refrescar</button>
                    <router-link to="/home" class="menu-link">Volver al Home</router-link>
                </div>
            </div>
        </div>

        <!-- Aquí posicionamos el SnackBar justo debajo de la barra superior -->
        <div>
            <snack-bar :isVisible="isSnackBarVisible" :message="snackBarMessage" class="snackbar-position" />
        </div>

        <!-- Fila inferior que contiene la lista de números y el panel de conversación -->
        <div class="bottom-row">
            <!-- Lista de números únicos (Izquierda) -->
            <div class="conversation-list">
                <ul>
                    <li v-for="(messages, phoneNumber) in conversations" :key="phoneNumber"
                        @click="selectConversation(phoneNumber)"
                        :class="{ active: phoneNumber === selectedConversation, 'has-notification': unreadConversations[phoneNumber] }">
                        {{ phoneNumber }}
                    </li>
                </ul>
            </div>
            <!-- Panel de conversación (Derecha) -->
            <div v-if="selectedConversation" class="chat-panel">
                <!-- Botón "Marcar como leído" solo aparece si hay mensajes 'received' -->
                <div id="chat-header">
                    {{ selectedConversation }}
                    <button class="mark-read-button" v-if="lastEvaluatedKey !== null" @click="loadMoreMessages">
                        Cargar mas mensajes
                    </button>

                    <button class="mark-read-button" v-if="hasUnreadMessages" @click="markAsRead">Marcar
                        como leído</button>
                </div>

                <!-- Cintillo informativo -->
                <div v-if="shouldShowInfoBanner" class="info-banner">
                    <!-- Mostrar el mensaje correspondiente según el tipo de mensaje fallido -->
                    <div v-if="showTextMessageError">
                        ⛔ El tiempo para enviar mensajes directos ha expirado. Te sugerimos utilizar una plantilla para
                        continuar la conversación.
                    </div>

                    <div v-if="showTemplateMessageError">
                        ⛔ Parece que hubo un problema para entregar este mensaje. Es posible que el número no tenga
                        cuenta de WhatsApp.
                    </div>
                </div>

                <div id="chat-messages">
                    <div v-for="(message, index) in messages" :key="message.MessageId" :class="['message', message.Direction, {
                        'received-message': message.Status === 'received',
                        'failed-message': message.Status === 'failed'
                    }]">
                        <!-- Mensaje tipo texto -->
                        <div v-if="message.MessageType === 'text'" class="message-body">
                            {{ message.MessageContent?.Body || 'Mensaje no disponible' }}
                        </div>
                        <!-- Mensaje tipo template -->
                        <div v-if="message.MessageType === 'template'" class="message-body">
                            <h3 v-if="message.MessageContent?.Header">{{ message.MessageContent.Header }}</h3>
                            <p class="message-body-content"
                                v-html="formatBodyWithLineBreaks(message.MessageContent?.Body || '')"></p>
                            <footer v-if="message.MessageContent?.Footer">{{ message.MessageContent.Footer }}</footer>
                            <div v-if="message.MessageContent?.Buttons?.length > 0" class="buttons-container">
                                <button v-for="button in message.MessageContent.Buttons" :key="button.text"
                                    class="template-button disabled-button">
                                    {{ button.text || 'Texto no disponible' }}
                                </button>
                            </div>
                        </div>
                        <!-- Mensaje tipo imagen -->
                        <div v-if="message.MessageType === 'image'" class="message-body">
                            <img v-if="message.MessageContent?.Content?.imagePath"
                                :src="message.MessageContent.Content.imagePath" alt="Imagen recibida"
                                class="received-image" @click="openImageModal(message.MessageContent.Content.imagePath)"
                                @error="handleImageError" />
                        </div>
                        <!-- Mensaje tipo boton (respuesta) -->
                        <div v-if="message.MessageType === 'button'" class="message-body">
                            <div class="message-button-content">
                                <button class="template-button disabled-button">
                                    {{ message.MessageContent?.Button?.text || 'Texto no disponible' }}
                                </button>
                            </div>
                            <div v-if="message.MessageContent?.ReferencedMessageId" class="referenced-message">
                                Respondiendo a: {{ findReferencedMessage(message.MessageContent.ReferencedMessageId) }}
                            </div>
                        </div>
                        <!-- Meta Data (footer del mensaje) -->
                        <div class="message-meta">
                            <div class="timestamp">{{ formatTimestamp(message.Timestamp) }}</div>
                            <div v-if="message.Direction === 'outbound'" class="status-indicator"
                                :class="getStatusClass(message.Status)" :title="message.Status">
                                {{ getStatusIcon(message.Status) }}
                            </div>
                            <button v-if="showRetryButton(message)" @click="retryMessage(message)" class="retry-icon">
                                🔄
                            </button>
                        </div>

                    </div>
                </div>

                <!-- Contenedor del textbox y botón de enviar -->
                <div class="input-container">
                    <textarea v-model="newMessage" placeholder="Escribe tu mensaje..."></textarea>
                    <button v-if="newMessage.trim() !== ''" @click="sendMessage">Enviar</button>
                </div>

                <!-- Modal para mostrar la imagen en tamaño completo -->
                <div v-if="isImageModalOpen" class="image-modal" @click="closeImageModal">
                    <img :src="selectedImagePath" alt="Imagen en tamaño completo" class="modal-image" />
                </div>
            </div>
        </div>

        <send-template-window ref="sendTemplateWindow" :isVisible="isModalVisible" @close="closeNewChat"
            @showSnackBar="showSnackBar" />
    </div>
</template>

<script>

import SendTemplateWindow from './SendTemplateWindow.vue'; // Cargar el componente del formulario
import SnackBar from './SnackBar.vue'; //Cargar el componente del snackbar

export default {
    components: {
        SendTemplateWindow,  // Nueva referencia al componente actualizado
        SnackBar,
    },
    data() {
        return {
            conversations: {},
            selectedConversation: null,
            messages: [],
            newMessage: '',
            isLoading: false,  // Controla el estado del loader
            isImageModalOpen: false,  // Controla el estado del modal de imagen
            selectedImagePath: null,  // Ruta de la imagen seleccionada
            showNewChat: false,
            menuOpen: false,
            isModalVisible: false, // Para controlar la visibilidad del modal
            isSnackBarVisible: false,
            snackBarMessage: '',
            lastEvaluatedKey: null
        };
    },
    methods: {
        async refreshConversations() {
            this.isLoading = true;
            const token = localStorage.getItem('jwtToken');
            if (!token || Date.now() > localStorage.getItem('tokenExpiration')) {
                this.$router.push('/login');
                return;
            }

            try {
                const response = await fetch('https://api.hey-matchr.com/conversations/last', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                const result = await response.json();
                if (result.body) {
                    const parsedBody = JSON.parse(result.body);
                    this.conversations = parsedBody;

                    // Si hay una conversación seleccionada, actualízala
                    if (this.selectedConversation) {
                        this.messages = this.conversations[this.selectedConversation]?.messages || [];
                    }
                } else {
                    console.error('No se pudo parsear la respuesta del API.');
                }
            } catch (error) {
                console.error('Error al refrescar las conversaciones:', error);
            } finally {
                this.isLoading = false;
            }
        },
        async markAsRead() {
            const token = localStorage.getItem('jwtToken');
            const unreadMessages = this.messages.filter(message => message.Status === 'received' && message.Direction === 'inbound');

            if (unreadMessages.length === 0) return; // No hacer nada si no hay mensajes sin leer

            const payload = {
                phoneNumber: this.selectedConversation,
                messageIds: unreadMessages.map(message => message.MessageId),
            };

            this.isLoading = true;  // Muestra el loader

            try {
                const response = await fetch('https://api.hey-matchr.com/conversations/update/statusByPhone', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                });

                // Verificar si la respuesta fue exitosa
                if (!response.ok) {
                    const errorText = await response.text();  // Leer el texto de error
                    console.error('Error al marcar los mensajes como leídos:', errorText);
                    return;
                }

                const result = await response.json();
                console.log('Mensajes marcados como leídos correctamente:', result);

                // Actualizar el estado local de los mensajes marcados
                unreadMessages.forEach(message => {
                    message.Status = 'read'; // Cambiar estatus a 'read'
                });

                // Refrescar la conversación para eliminar el resaltado
                this.messages = this.conversations[this.selectedConversation]?.messages || [];

            } catch (error) {
                console.error('Error en la petición:', error);
            } finally {
                this.isLoading = false;  // Desactivar el loader
            }
        },
        selectConversation(phoneNumber) {
            this.selectedConversation = phoneNumber;
            if (this.selectedConversation) {
                this.updateMessages(this.selectedConversation);
            }
        },
        async sendMessage() {
            if (!this.selectedConversation || this.newMessage.trim() === '') return;

            const token = localStorage.getItem('jwtToken');
            this.isLoading = true;  // Muestra el loader

            try {
                const response = await fetch('https://api.hey-matchr.com/conversations/sendMessage', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        type: 'text',
                        phoneNumber: this.selectedConversation,
                        text: { body: this.newMessage },
                    }),
                });

                const result = await response.json();

                if (response.ok && result.body) {
                    const parsedBody = JSON.parse(result.body);
                    const newMessage = parsedBody.data;

                    // Inicialmente marcamos el mensaje como "accepted"
                    newMessage.Status = 'accepted';

                    // Añadir el nuevo mensaje a la conversación actual
                    if (this.selectedConversation && newMessage) {
                        this.conversations[this.selectedConversation].messages.push(newMessage);
                    }

                    // Limpiar el campo de entrada de mensaje
                    this.newMessage = '';
                } else {
                    console.error('Error al enviar el mensaje', result);
                }

            } catch (error) {
                console.error('Error enviando el mensaje:', error);
            } finally {
                this.isLoading = false;  // Esconde el loader
            }
        }
        ,
        formatTimestamp(timestamp) {
            const date = new Date(parseInt(timestamp) * 1000);
            return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
        },
        // Obtener el ícono correspondiente al estado del mensaje
        getStatusIcon(status) {
            switch (status) {
                case 'accepted':
                    return '⏳';
                case 'sent':
                    return '✔';
                case 'delivered':
                    return '✔✔';
                case 'read':
                    return '✔✔';
                case 'failed':
                    return '⛔';
                default:
                    return '';
            }
        },
        // Asignar clase adicional en caso de estatus failed
        getStatusClass(status) {
            if (status === 'failed') return 'failed';
            return status === 'read' ? 'read' : '';
        },

        findReferencedMessage(referencedId) {
            const allMessages = Object.values(this.conversations).flatMap(conv => conv.messages);
            const referencedMessage = allMessages.find(msg => msg.MessageId === referencedId);
            return referencedMessage ? referencedMessage.MessageContent?.Body : referencedId;
        },
        handleImageError(event) {
            console.error('Error al cargar la imagen:', event);
            event.target.src = '/path-to-placeholder-image'; // Imagen de reemplazo en caso de error
        },
        openImageModal(imagePath) {
            this.selectedImagePath = imagePath;
            this.isImageModalOpen = true;
        },
        closeImageModal() {
            this.isImageModalOpen = false;
            this.selectedImagePath = null;
        },
        formatBodyWithLineBreaks(body) {
            return body.replace(/\n/g, '<br>');
        },
        toggleMenu() {
            this.menuOpen = !this.menuOpen;
        },
        openNewChat() {
            this.isModalVisible = true;
            this.$refs.sendTemplateWindow.resetForm(); // Llamamos al método para resetear el formulario
        },
        closeNewChat() {
            this.isModalVisible = false;
        },
        // Mostrar el snack bar
        showSnackBar(message) {
            this.snackBarMessage = message;
            this.isSnackBarVisible = true;

            setTimeout(() => {
                this.isSnackBarVisible = false;
            }, 4000); // Ocultar el snack bar después de 3 segundos
        },
        async loadMoreMessages() {
            if (!this.selectedConversation || !this.lastEvaluatedKey) return;

            this.isLoading = true;
            const token = localStorage.getItem('jwtToken');

            try {
                const payload = {
                    phoneNumber: this.selectedConversation,
                    lastMessageAt: this.lastEvaluatedKey?.Timestamp || this.lastEvaluatedKey,
                };

                const response = await fetch('https://api.hey-matchr.com/conversations/getByPhone', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                });

                const result = await response.json();
                const parsedBody = result.body ? JSON.parse(result.body) : null;

                if (parsedBody && parsedBody.messages?.length > 0) {
                    this.addMessages(parsedBody.messages);
                    this.updateLastEvaluatedKey(parsedBody.lastEvaluatedKey);
                } else {
                    console.error('No se pudo parsear la respuesta del API.');
                }
            } catch (error) {
                console.error('Error al cargar más mensajes:', error);
            } finally {
                this.isLoading = false;
            }
        },
        addMessages(newMessages) {
            // Añadir los nuevos mensajes al inicio y mantener el orden cronológico
            this.messages = [...newMessages, ...this.messages].sort((a, b) => {
                return parseInt(a.Timestamp) - parseInt(b.Timestamp);
            });

            this.conversations[this.selectedConversation].messages = this.messages;
        },
        updateMessages(phoneNumber) {
            this.messages = this.conversations[phoneNumber]?.messages || [];
            this.lastEvaluatedKey = this.conversations[phoneNumber]?.lastEvaluatedKey || null;
        },
        updateLastEvaluatedKey(lastEvaluatedKey) {
            if (lastEvaluatedKey === null) {
                this.lastEvaluatedKey = null;
                this.conversations[this.selectedConversation].lastEvaluatedKey = null;
            } else {
                this.lastEvaluatedKey = lastEvaluatedKey?.Timestamp || lastEvaluatedKey;
                this.conversations[this.selectedConversation].lastEvaluatedKey = this.lastEvaluatedKey;
            }
        },
        // Función para determinar si el botón de "Reintento" debe mostrarse
        showRetryButton(message) {
            return (
                message.Status === 'failed' && // Mensaje fallido
                message.Direction === 'outbound' && // Es un mensaje saliente
                message.MessageType === 'template' && // Es de tipo plantilla
                !message.Retried && // No ha sido reintentado
                message === this.messages[this.messages.length - 1] // Asegurarse que es el último mensaje
            );
        },
        retryMessage(message) {
            // Mostrar el loader mientras se reintenta el mensaje
            this.isLoading = true;

            // Obtener el token de sesión almacenado
            const token = localStorage.getItem('jwtToken');

            if (!token) {
                this.showSnackBar('Error: No se encontró un token de autenticación.');
                this.isLoading = false;
                return;
            }

            // Definir el payload con los datos del mensaje
            const payload = {
                phoneNumber: this.selectedConversation,
                timestamp: message.Timestamp, // Timestamp del mensaje original
                messageId: message.MessageId   // ID del mensaje original
            };

            // Realizar la petición para reintentar el envío
            fetch('https://api.hey-matchr.com/conversations/retrySendMessage', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            })
                .then(async response => {
                    const result = await response.json();
                    if (response.ok) {
                        // La respuesta fue exitosa, actualizar la conversación con el nuevo mensaje
                        const updatedMessage = JSON.parse(result.body).updatedMessage;

                        // Actualizar la conversación con el nuevo messageId y estado del mensaje
                        const messages = this.conversations[this.selectedConversation].messages;
                        const messageIndex = messages.findIndex(m => m.MessageId === message.MessageId);

                        if (messageIndex !== -1) {
                            // Reemplazar el mensaje original con el nuevo mensaje actualizado
                            this.conversations[this.selectedConversation].messages[messageIndex] = updatedMessage;
                        }

                        // Mostrar un mensaje en el SnackBar
                        this.showSnackBar("Mensaje reintentado exitosamente.");
                    } else {
                        // Si hubo un error en la petición
                        this.showSnackBar(`Error al reintentar el mensaje: ${result.message}`);
                    }
                })
                .catch(error => {
                    // Manejo de errores durante la llamada a la API
                    console.error('Error al reintentar el mensaje:', error);
                    this.showSnackBar("Ocurrió un error al intentar reintentar el mensaje.");
                })
                .finally(() => {
                    // Ocultar el loader
                    this.isLoading = false;
                });
        }
    },
    computed: {
        hasUnreadMessages() {
            return Array.isArray(this.messages) && this.messages.some(message => message.Direction === 'inbound' && message.Status === 'received');
        },
        shouldShowInfoBanner() {
            // Verificar si hay algún mensaje con status 'failed' + outbound + tipo 'text' o 'template'
            return this.showTextMessageError || this.showTemplateMessageError;
        },
        showTextMessageError() {
            // Retorna verdadero si hay algún mensaje fallido de tipo 'text' + outbound
            return Array.isArray(this.messages) && this.messages.some(message =>
                message.Status === 'failed' &&
                message.Direction === 'outbound' &&
                message.MessageType === 'text'
            );
        },
        showTemplateMessageError() {
            // Retorna verdadero si hay algún mensaje fallido de tipo 'template' + outbound
            return Array.isArray(this.messages) && this.messages.some(message =>
                message.Status === 'failed' &&
                message.Direction === 'outbound' &&
                message.MessageType === 'template'
            );
        },
        unreadConversations() {
            const unread = {};
            for (const [phoneNumber, conversation] of Object.entries(this.conversations)) {
                unread[phoneNumber] = conversation.messages.some(
                    message => message.Direction === 'inbound' && message.Status === 'received'
                );
            }
            return unread;
        },
    },
    mounted() {
        this.refreshConversations();
    },
};
</script>

<style scoped>
/* General Layout */
.conversations-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

/* NOTIFICACIONES */
/* Para el listado de teléfonos con notificaciones */
.conversation-list li.has-notification {
    background-color: #fdfdf0;
    /* Fondo suave */
    border: 1px solid #008000;
    /* Borde resaltado en amarillo */
    padding: 10px;
    border-radius: 4px;
    /* Bordes redondeados */
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    /* Sombra sutil */
    margin-bottom: 8px;
    /* Espaciado entre elementos */
}

/* Para los mensajes recibidos con estatus 'received' */
.received-message {
    border: 1.5px solid #008000;
    /* Borde resaltado en amarillo */
    background-color: #fdfdf0;
    /* Fondo suave */
    padding: 10px;
    border-radius: 4px;
    /* Bordes redondeados */
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    /* Sombra sutil */
    margin-bottom: 8px;
    /* Espaciado entre mensajes */
}



/* Loader centrado */
#loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    /* Transparencia del fondo */
    font-size: 1.5em;
    color: #333;
    z-index: 9999;
    /* Para asegurarse de que esté por encima de todo */
}

/* Barra superior */
.top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #075e54;
    padding: 10px;
    color: white;
}

/* Estilos para el botón de Nuevo Chat */
.new-chat-btn {
    background-color: #4CAF50;
    color: white;
    padding: 12px 20px;
    border-radius: 8px;
    border: none;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.new-chat-btn:hover {
    background-color: #45a049;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
}

.new-chat-btn:active {
    background-color: #3e8e41;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
    transform: translateY(2px);
    /* Simulación de "presionado" */
}


.refresh-button:hover {
    background-color: #ebebeb;
}

.title {
    font-size: 1.2em;
}

.home-link {
    color: white;
    text-decoration: none;
}

.home-link:hover {
    text-decoration: underline;
}

/* Fila inferior con lista de números y panel de conversación */
.bottom-row {
    display: flex;
    flex-grow: 1;
}

/* Lista de conversaciones (Números) */
.conversation-list {
    width: 30%;
    background-color: #fff;
    border-right: 1px solid #ddd;
    padding-right: 5px;
    padding-left: 5px;
    overflow-y: auto;
}

.conversation-list ul {
    list-style-type: none;
    padding: 0;
}

.conversation-list li {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
}

.conversation-list li.active {
    background-color: #e0f7fa;
    font-weight: bold;
}

.conversation-list li:hover {
    background-color: #f5f5f5;
}

/* Panel de conversación */
.chat-panel {
    width: 70%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: #e5ddd5;
}

#chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#chat-messages {
    flex-grow: 1;
    padding: 10px;
    overflow-y: auto;
}

/* Mensaje con cuerpo y meta info */
.message {
    padding: 7px;
    margin-bottom: 8px;
    border-radius: 5px;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 70%;
    /* Ocupan el 70% del panel */
}

.message.outbound {
    background-color: #dcf8c6;
    margin-left: auto;
    /* Alineación hacia la derecha */
}

.message.inbound {
    background-color: #fff;
    margin-right: auto;
    /* Alineación hacia la izquierda */
}

/* Reducir tamaño general de la fuente */
.message-body {
    font-size: 0.8em;
    /* Reducción general del tamaño de la fuente */
}

/* Meta info de mensaje: timestamp y estatus */
.message-meta {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1px;
    /* Reducir el espacio entre mensaje y timestamp */
    font-size: 0.75em;
    /* Reducir tamaño de la fuente del timestamp */
}

.timestamp {
    /* Añadir espacio entre el timestamp y las palomitas */
    font-size: 0.75em;
    color: #555;
}

.status-indicator {
    font-size: 0.75em;
    color: #777;
    margin-left: 5px;
}

.status-indicator.read {
    color: #5BD1D7;
}

/* Mensaje referenciado */
.referenced-message {
    font-size: 0.85em;
    color: #777;
    margin-bottom: 5px;
    font-style: italic;
}

/* Caja de entrada de texto y botón */
.input-container {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

textarea {
    width: 90%;
    /* Ajusta el ancho para que no se desborde cuando aparezca el botón */
    padding: 10px;
    border-radius: 5px;
    resize: none;
}

button {
    background-color: #075e54;
    color: white;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
}

button:hover {
    background-color: #064a45;
}

/* Estilo del botón "Marcar como leído" en el encabezado */
.mark-read-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
}

.mark-read-button:hover {
    background-color: #45a049;
}

.message-body.image {
    font-style: italic;
    color: #777;
}

/* Imagen recibida */
.received-image {
    max-width: 250px;
    /* Define un ancho máximo más pequeño */
    height: auto;
    border-radius: 5px;
    margin-top: 10px;
    cursor: pointer;
    /* Mantenemos el cursor como puntero */
}

/* Modal de imagen */
.image-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    /* Fondo oscuro con transparencia */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    /* Coloca el modal por encima de todo */
    cursor: zoom-out;
}

.modal-image {
    max-width: 90%;
    max-height: 90%;
    object-fit: contain;
    border-radius: 10px;
}

/* Aplicar el estilo para respetar los saltos de línea */
.message-body-content {
    white-space: pre-wrap;
    /* Respetar los saltos de línea \n */
}

/* Estilo de los botones con más espacio entre ellos */
.template-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px;
    margin: 5px 10px;
    /* Añadir margen a los botones */
    border-radius: 5px;
    border: none;
    cursor: pointer;
}

/* Botones de plantilla no accionables */
.disabled-button {
    background-color: #e0e0e0;
    /* Color de fondo gris para botones deshabilitados */
    color: #999999;
    /* Texto en gris claro */
    border: 1px solid #cccccc;
    /* Borde gris claro */
    padding: 10px;
    margin: 5px 10px;
    border-radius: 5px;
    cursor: not-allowed;
    /* Cursor de "no permitido" */
    pointer-events: none;
    /* Desactivar clics o cualquier evento */
}

.disabled-button:hover {
    background-color: #e0e0e0;
    /* Mantener el color en hover */
    color: #999999;
    /* Mantener el color del texto en hover */
}

/* Aplicar borde rojo a todo el mensaje cuando esté en estado failed */
.failed-message {
    border: 1px solid red;
    /* Borde rojo delgado para mensajes fallidos */
}

.info-banner {
    background-color: #ffcccc;
    color: #cc0000;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid red;
    font-size: 0.7em;
    text-align: center;
}

/* Menú */
.menu {
    position: relative;
}

/* Menú desplegable */
.menu-button {
    background-color: #075e54;
    border: none;
    color: white;
    font-size: 24px;
    /* Hacer más grande el ícono del menú */
    padding: 12px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.menu-button:hover {
    background-color: #064a45;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
}

.menu-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    min-width: 160px;
    padding: 10px 0;
    z-index: 1000;
}

.menu-dropdown button,
.menu-link {
    padding: 12px 20px;
    display: block;
    width: 100%;
    text-align: left;
    background-color: transparent;
    border: none;
    color: #333;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.menu-dropdown button:hover,
.menu-link:hover {
    background-color: #f0f0f0;
    color: #075e54;
}

.menu-dropdown button:active,
.menu-link:active {
    background-color: #e0e0e0;
    transform: translateY(2px);
}

.retry-icon {
    background: none;
    border: none;
    cursor: pointer;
    color: #777;
    /* Mismo color que el estado */
    font-size: 0.75em;
    /* Tamaño similar al icono del estado */
    margin-left: 5px;
    /* Separación discreta del icono del estado */
    padding: 0;
    transition: transform 0.2s ease;
    /* Transición suave para el hover */
}

.retry-icon:hover {
    color: #dcf8c6;
    /* Cambiar de color al hacer hover si lo deseas */
    transform: scale(1.1);
    /* Aumentar el tamaño un 10% al hacer hover */
}
</style>
