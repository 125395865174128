<template>
    <div class="crm-container">
      <!-- Loader -->
      <div id="loading" v-if="isLoading">Cargando...</div>
      
      <h1>CRM en construcción...</h1>
      <!-- Cambiar el enlace para volver al Home -->
      <router-link to="/home">Volver al Home</router-link>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isLoading: false, // Loader para futura implementación
      };
    },
  };
  </script>
  
  <style scoped>
  .crm-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .crm-container h1 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  </style>
  