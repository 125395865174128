<template>
    <transition name="fade">
      <div v-if="isVisible" class="snackbar">{{ message }}</div>
    </transition>
  </template>
  
  <script>
  export default {
    props: {
      isVisible: {
        type: Boolean,
        default: false,
      },
      message: {
        type: String,
        default: '',
      },
    },
  };
  </script>
  
  <style scoped>
.snackbar {
  position: absolute; /* Posición relativa al contenedor */
  left: 0; /* Para que ocupe todo el ancho desde el borde izquierdo */
  width: 100%; /* Para que ocupe todo el ancho de la pantalla */
  background-color: #4caf50; /* Color verde brillante */
  color: white;
  padding: 8px; /* Aumentamos un poco el padding para más espacio */
  border-radius: 0; /* Sin bordes redondeados para darle un aspecto de barra completa */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 14px; /* Reducimos el tamaño de la fuente */
  font-weight: normal; /* Hacemos el texto más discreto */
  border: none; /* Sin bordes extra */
  text-align: center; /* Centramos el texto */
  z-index: 1000;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>