<template>
    <div class="modal-overlay" v-if="isVisible" @click.self="closeModal">
        <div class="popup-container">
            <h2 class="popup-title">Nuevo Chat</h2>

            <!-- Cargando -->
            <div v-if="isLoading" class="loading-overlay">Cargando...</div>

            <!-- Teléfono -->
            <div class="form-group">
                <div class="phone-input">
                    <span class="flag">🇲🇽</span>
                    <span class="prefix">+52</span>
                    <input type="text" v-model="phoneNumber" maxlength="10"
                        :class="{ 'error-border': showErrors.phoneNumber, 'valid-input': phoneNumber !== '' }"
                        :placeholder="showErrors.phoneNumber && touchedFields.phoneNumber ? 'Número inválido' : 'Ingrese número de teléfono'"
                        @focus="touchField('phoneNumber')" @blur="validateField('phoneNumber')" />
                </div>
            </div>

            <!-- Nombre -->
            <div class="form-group">
                <input type="text" v-model="name"
                    :class="{ 'error-border': showErrors.name, 'valid-input': name !== '' }"
                    :placeholder="showErrors.name && touchedFields.name ? 'Nombre requerido' : 'Ingrese el nombre'"
                    @focus="touchField('name')" @blur="validateField('name')" />
            </div>

            <!-- Selección de Plantilla -->
            <div class="form-group">
                <select v-model="selectedTemplate"
                    :class="{ 'error-border': showErrors.selectedTemplate, 'valid-input': selectedTemplate !== '' }"
                    @focus="touchField('selectedTemplate')" @blur="validateField('selectedTemplate')">
                    <option disabled value="" class="placeholder-option">{{ showErrors.selectedTemplate &&
                        touchedFields.selectedTemplate ? 'Debe seleccionar una plantilla' : 'Seleccione plantilla' }}
                    </option>
                    <option value="Musico buscando banda">Músico buscando banda</option>
                    <option value="Banda buscando musicos">Banda buscando músicos</option>
                </select>
            </div>

            <!-- Campos para "Músico buscando banda" -->
            <div v-if="selectedTemplate === 'Musico buscando banda'">
                <!-- Selección de Instrumento -->
                <div class="form-group">
                    <select v-model="selectedInstrument"
                        :class="{ 'error-border': showErrors.selectedInstrument, 'valid-input': selectedInstrument !== '' }"
                        @focus="touchField('selectedInstrument')" @blur="validateField('selectedInstrument')">
                        <option disabled value="" class="placeholder-option">{{ showErrors.selectedInstrument &&
                            touchedFields.selectedInstrument ? 'Debe seleccionar un instrumento' : 'Selecciona Instrumento' }}
                        </option>
                        <option value="Guitarra">Guitarra</option>
                        <option value="Bateria">Batería</option>
                        <option value="Bajo">Bajo</option>
                        <option value="Teclado">Teclado</option>
                        <option value="Otro">Otro</option>
                    </select>
                </div>

                <div v-if="selectedInstrument === 'Otro'">
                    <!-- Otro Instrumento -->
                    <div class="form-group">
                        <input type="text" v-model="instrumentOtro"
                            :class="{ 'error-border': showErrors.instrumentOtro, 'valid-input': instrumentOtro !== '' }"
                            :placeholder="showErrors.instrumentOtro && touchedFields.instrumentOtro ? 'Requerido' : 'Especifica Instrumento'"
                            @focus="touchField('instrumentOtro')" @blur="validateField('instrumentOtro')" />
                    </div>
                </div>

                <!-- Interés -->
                <div class="form-group">
                    <select v-model="interest"
                        :class="{ 'error-border': showErrors.interest, 'valid-input': interest !== '' }"
                        @focus="touchField('interest')" @blur="validateField('interest')">
                        <option disabled value="" class="placeholder-option">{{ showErrors.interest &&
                            touchedFields.interest ? 'Valor requerido' : 'Seleccione interés' }}</option>
                        <option value="Trabajo Fijo">Trabajo Fijo</option>
                        <option value="Trabajo Ocasional">Trabajo Ocasional</option>
                        <option value="Hobby">Hobby</option>
                    </select>
                </div>

                <!-- Nivel -->
                <div class="form-group">
                    <select v-model="level" :class="{ 'error-border': showErrors.level, 'valid-input': level !== '' }"
                        @focus="touchField('level')" @blur="validateField('level')">
                        <option disabled value="" class="placeholder-option">{{ showErrors.level && touchedFields.level
                            ? 'Valor requerido' : 'Seleccione nivel' }}</option>
                        <option value="Principiante">Principiante</option>
                        <option value="Intermedio">Intermedio</option>
                        <option value="Avanzado">Avanzado</option>
                    </select>
                </div>

                <!-- Géneros -->
                <div class="form-group">
                    <div class="genres-input">
                        <div v-for="(genre, index) in genres" :key="index" class="genre-item">
                            <input type="text" v-model="genres[index]"
                                :class="{ 'error-border': showErrors.genres && genres[index] === '', 'valid-input': genres[index] !== '' }"
                                :placeholder="showErrors.genres && genres[index] === '' ? 'Género requerido' : 'Ingrese un género'" />
                            <button class="remove-btn" @click="removeGenre(index)">-</button>
                            <button v-if="index === genres.length - 1" class="add-genre-btn"
                                @click="addGenre">+</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Campos para "Banda buscando músicos" -->
            <div v-if="selectedTemplate === 'Banda buscando musicos'">
                <!-- Nombre de la banda -->
                <div class="form-group">
                    <input type="text" v-model="bandName"
                        :class="{ 'error-border': showErrors.bandName, 'valid-input': bandName !== '' }"
                        :placeholder="showErrors.bandName && touchedFields.bandName ? 'Nombre de banda requerido' : 'Ingrese el nombre de la banda'"
                        @focus="touchField('bandName')" @blur="validateField('bandName')" />
                </div>

                <!-- Interés -->
                <div class="form-group">
                    <select v-model="interest"
                        :class="{ 'error-border': showErrors.interest, 'valid-input': interest !== '' }"
                        @focus="touchField('interest')" @blur="validateField('interest')">
                        <option disabled value="" class="placeholder-option">{{ showErrors.interest &&
                            touchedFields.interest ? 'Valor requerido' : 'Seleccione interés' }}</option>
                        <option value="Trabajo Fijo">Trabajo Fijo</option>
                        <option value="Trabajo Ocasional">Trabajo Ocasional</option>
                        <option value="Hobby">Hobby</option>
                    </select>
                </div>

                <!-- Nivel -->
                <div class="form-group">
                    <select v-model="level" :class="{ 'error-border': showErrors.level, 'valid-input': level !== '' }"
                        @focus="touchField('level')" @blur="validateField('level')">
                        <option disabled value="" class="placeholder-option">{{ showErrors.level && touchedFields.level
                            ? 'Valor requerido' : 'Seleccione nivel' }}</option>
                        <option value="Principiante">Principiante</option>
                        <option value="Intermedio">Intermedio</option>
                        <option value="Avanzado">Avanzado</option>
                    </select>
                </div>

                <!-- Géneros -->
                <div class="form-group">
                    <div class="genres-input">
                        <div v-for="(genre, index) in genres" :key="index" class="genre-item">
                            <input type="text" v-model="genres[index]"
                                :class="{ 'error-border': showErrors.genres && genres[index] === '', 'valid-input': genres[index] !== '' }"
                                :placeholder="showErrors.genres && genres[index] === '' ? 'Género requerido' : 'Ingrese un género'" />
                            <button class="remove-btn" @click="removeGenre(index)">-</button>
                            <button v-if="index === genres.length - 1" class="add-genre-btn"
                                @click="addGenre">+</button>
                        </div>
                    </div>
                </div>

                <!-- Instrumentos que necesitas en tu banda -->
                <div class="form-group">
                    <label>Instrumentos que necesitas en tu banda</label>
                    <div class="instruments-input">
                        <div v-for="(instrument, index) in neededInstruments" :key="index" class="genre-item">
                            <input type="text" v-model="neededInstruments[index]"
                                :class="{ 'error-border': showErrors.neededInstruments && neededInstruments[index] === '', 'valid-input': neededInstruments[index] !== '' }"
                                :placeholder="showErrors.neededInstruments && neededInstruments[index] === '' ? 'Instrumento requerido' : 'Ingrese un instrumento'" />
                            <button class="remove-btn" @click="removeInstrument(index)">-</button>
                            <button v-if="index === neededInstruments.length - 1" class="add-genre-btn"
                                @click="addInstrument">+</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Botón de enviar -->
            <div class="form-actions">
                <button class="submit-btn" :disabled="!isFormValid" @click="submitForm">Enviar</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isVisible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            phoneNumber: '',
            name: '',
            selectedTemplate: '',
            bandName: '', // Nombre de la banda para "Banda buscando músicos"
            selectedInstrument: '',
            instrumentOtro: '',
            interest: '',
            level: '',
            genres: [''],
            neededInstruments: [''], // Instrumentos que necesita la banda
            touchedFields: {
                phoneNumber: false,
                name: false,
                selectedTemplate: false,
                bandName: false,
                selectedInstrument: false,
                instrumentOtro: false,
                interest: false,
                level: false,
                genres: false,
                neededInstruments: false
            },
            showErrors: {
                phoneNumber: false,
                name: false,
                selectedTemplate: false,
                bandName: false,
                selectedInstrument: false,
                instrumentOtro: false,
                interest: false,
                level: false,
                genres: false,
                neededInstruments: false
            },
            isLoading: false
        };
    },
    computed: {
        isValidPhone() {
            return /^[0-9]{10}$/.test(this.phoneNumber);
        },
        isFormValid() {
            if (this.selectedTemplate === 'Musico buscando banda') {
                return (
                    this.isValidPhone &&
                    this.name !== '' &&
                    this.selectedInstrument !== '' &&
                    (this.selectedInstrument !== 'Otro' || this.instrumentOtro !== '') &&
                    this.interest !== '' &&
                    this.level !== '' &&
                    this.genres.length > 0 &&
                    this.genres[0] !== ''
                );
            } else if (this.selectedTemplate === 'Banda buscando musicos') {
                return (
                    this.isValidPhone &&
                    this.name !== '' &&
                    this.bandName !== '' &&
                    this.interest !== '' &&
                    this.level !== '' &&
                    this.genres.length > 0 &&
                    this.genres[0] !== '' &&
                    this.neededInstruments.length > 0 &&
                    this.neededInstruments[0] !== ''
                );
            }
            return false;
        }
    },
    methods: {
        touchField(field) {
            this.touchedFields[field] = true;
        },
        validateField(field) {
            if (this.touchedFields[field]) {
                if (field === 'phoneNumber') {
                    this.showErrors.phoneNumber = !this.isValidPhone;
                } else {
                    this.showErrors[field] = this[field] === '';
                }
            }
        },
        addGenre() {
            this.genres.push('');
        },
        removeGenre(index) {
            this.genres.splice(index, 1);
        },
        addInstrument() {
            this.neededInstruments.push('');
        },
        removeInstrument(index) {
            this.neededInstruments.splice(index, 1);
        },
        closeModal() {
            this.$emit('close'); // Emite un evento para cerrar el modal
        },
        async submitForm() {
            // Mostrar estado de "Cargando"
            this.isLoading = true;

            const token = localStorage.getItem('jwtToken');
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json'
            };

            let data;

            if (this.selectedTemplate === 'Musico buscando banda') {
                // Payload para "Músico buscando banda"
                data = {
                    type: 'template',
                    templateId: 'welcome_matchr_single',
                    phoneNumber: `+52${this.phoneNumber}`,
                    vars: [
                        {
                            type: 'header',
                            parameters: [
                                {
                                    type: 'text',
                                    text: this.name // Nombre del formulario
                                }
                            ]
                        },
                        {
                            type: 'body',
                            parameters: [
                                {
                                    type: 'text',
                                    text: this.interest // Interés del formulario
                                },
                                {
                                    type: 'text',
                                    text: this.selectedInstrument === 'Otro' ? this.instrumentOtro : this.selectedInstrument // Instrumento o "Otro"
                                },
                                {
                                    type: 'text',
                                    text: this.genres.join(', ') // Generos concatenados con comas
                                },
                                {
                                    type: 'text',
                                    text: this.level // Nivel del formulario
                                }
                            ]
                        }
                    ]
                };
            } else if (this.selectedTemplate === 'Banda buscando musicos') {
                // Payload para "Banda buscando músicos"
                data = {
                    type: 'template',
                    templateId: 'matchr_welcome_band',
                    phoneNumber: `+52${this.phoneNumber}`,
                    vars: [
                        {
                            type: 'header',
                            parameters: [
                                {
                                    type: 'text',
                                    text: this.name // Nombre de la persona
                                }
                            ]
                        },
                        {
                            type: 'body',
                            parameters: [
                                {
                                    type: 'text',
                                    text: this.bandName // Nombre de la banda
                                },
                                {
                                    type: 'text',
                                    text: this.neededInstruments.join(', ') // Instrumentos que necesita la banda
                                },
                                {
                                    type: 'text',
                                    text: this.level // Nivel
                                },
                                {
                                    type: 'text',
                                    text: this.genres.join(', ') // Géneros concatenados
                                },
                                {
                                    type: 'text',
                                    text: this.interest // Interés
                                }
                            ]
                        }
                    ]
                };
            }

            try {
                const response = await fetch('https://api.hey-matchr.com/conversations/sendMessage', {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify(data)
                });
                const result = await response.json();
                // Verifica si result.body es un string y conviértelo en JSON si es necesario
                let parsedBody = result.body;
                if (typeof parsedBody === 'string') {
                    parsedBody = JSON.parse(parsedBody);
                }
                const messageStatus = parsedBody.data.Status;
                // Mostrar el snack bar con el estado del mensaje
                if (messageStatus) {
                    this.$emit('showSnackBar', `Mensaje enviado exitosamente. Status: ${messageStatus}`);
                }
                this.closeModal(); // Cerrar modal tras el envío
            } catch (error) {
                console.error('Error enviando el mensaje:', error);
            } finally {
                this.isLoading = false; // Ocultar "Cargando"
            }
        },
        resetForm() {
            this.phoneNumber = '';
            this.name = '';
            this.selectedTemplate = '';
            this.bandName = '';
            this.selectedInstrument = '';
            this.instrumentOtro = '';
            this.interest = '';
            this.level = '';
            this.genres = [''];
            this.neededInstruments = [''];
            this.touchedFields = {
                phoneNumber: false,
                name: false,
                selectedTemplate: false,
                bandName: false,
                selectedInstrument: false,
                instrumentOtro: false,
                interest: false,
                level: false,
                genres: false,
                neededInstruments: false
            };
            this.showErrors = {
                phoneNumber: false,
                name: false,
                selectedTemplate: false,
                bandName: false,
                selectedInstrument: false,
                instrumentOtro: false,
                interest: false,
                level: false,
                genres: false,
                neededInstruments: false
            };
        }
    }
};
</script>


<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup-container {
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.popup-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5em;
}

.form-group {
    margin-bottom: 15px;
}

.phone-input {
    display: flex;
    align-items: center;
}

.flag {
    font-size: 24px;
    margin-right: 10px;
}

.prefix {
    margin-right: 10px;
}

input[type='text'],
select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    margin: 0;
    box-sizing: border-box;
}

/* Placeholder gris por defecto */
input::placeholder,
select option[disabled] {
    color: #a9a9a9;
}

/* Texto del usuario en negro */
input.valid-input,
select.valid-input {
    color: black;
}

/* Placeholder y borde en rojo para errores */
input.error-border,
select.error-border {
    border-color: red;
}

input.error-border::placeholder,
select.error-border option[disabled] {
    color: red;
}

/* Mensaje de error */
.error-message {
    color: red;
    font-size: 0.85em;
    margin-top: 5px;
}

.genres-input {
    display: flex;
    flex-direction: column;
}

.genre-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.genre-item input {
    flex-grow: 1;
}

.remove-btn,
.add-genre-btn {
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 18px;
    padding: 0;
    margin-left: 8px;
}

.remove-btn:hover {
    background-color: #c0392b;
}

.add-genre-btn:hover {
    background-color: #2980b9;
}

.form-actions {
    text-align: center;
}

.submit-btn {
    background-color: #2ecc71;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
}

.submit-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* Cargando */
.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    color: #333;
    z-index: 999;
}
</style>
