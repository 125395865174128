<!-- src/views/LoginView.vue -->
<template>
    <div id="login-container">
      <!-- Loader -->
      <div id="loading" v-if="isLoading">Cargando...</div>
  
      <form @submit.prevent="login">
        <input type="text" v-model="username" placeholder="Username" required autocomplete="username">
        <input type="password" v-model="password" placeholder="Password" required autocomplete="current-password">
        <button type="submit">Login</button>
        <div id="login-error" v-if="error">{{ error }}</div>
      </form>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        username: '',
        password: '',
        error: null,
        isLoading: false,
      };
    },
    methods: {
      async login() {
        this.isLoading = true;
        try {
          const response = await fetch('https://api.hey-matchr.com/auth/signin', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username: this.username, password: this.password }),
          });
  
          const result = await response.json();
  
          if (response.ok && result.statusCode === 200) {
            const parsedBody = JSON.parse(result.body); // Parsear el body que es un string JSON
            const token = parsedBody.token;
            const expiration = this.decodeJWT(token).exp * 1000; // Obtener la expiración en milisegundos
  
            // Guardar token y expiración en localStorage
            localStorage.setItem('jwtToken', token);
            localStorage.setItem('tokenExpiration', expiration);
  
            // Redirigir al Home
            this.$router.push('/home');
          } else {
            this.error = 'Login failed. Please try again.';
          }
        } catch (error) {
          this.error = 'An error occurred. Please try again.';
        }
        this.isLoading = false;
      },
      decodeJWT(token) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        return JSON.parse(atob(base64));
      },
    },
    mounted() {
        // Al montar el componente, eliminar cualquier token y expiración en el localStorage
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('tokenExpiration');
    }
  };
  </script>
  
  <style scoped>
  #login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  form {
    display: flex;
    flex-direction: column;
    width: 300px;
  }
  
  input {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  button {
    background-color: #075e54;
    color: white;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
  }
  
  button:hover {
    background-color: #064a45;
  }
  
  #login-error {
    color: red;
    margin-top: 10px;
  }
  </style>
  