import { createRouter, createWebHistory } from 'vue-router';
import LoginView from '../views/LoginView.vue';
import HomeView from '../views/HomeView.vue';
import CRMView from '../views/CRMView.vue';
import ConversationsView from '../views/ConversationsView.vue';

// Función para verificar si el token está presente y no ha expirado
function isTokenValid() {
  const token = localStorage.getItem('jwtToken');
  const expiration = localStorage.getItem('tokenExpiration');

  if (token && expiration) {
    const now = Date.now();
    return now < parseInt(expiration); // Verifica si el token aún no ha expirado
  }

  return false; // Si no hay token o expiró, devuelve false
}

const routes = [
  {
    path: '/',
    name: 'Login',
    component: LoginView,
  },
  {
    path: '/home',
    name: 'Home',
    component: HomeView,
    meta: { requiresAuth: true }, // Indicar que esta ruta requiere autenticación
  },
  {
    path: '/crm',
    name: 'CRM',
    component: CRMView,
    meta: { requiresAuth: true }, // Esta ruta también requiere autenticación
  },
  {
    path: '/conversations',
    name: 'Conversations',
    component: ConversationsView,
    meta: { requiresAuth: true }, // Esta también requiere autenticación
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Guardián global para verificar el token antes de cada navegación
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (isTokenValid()) {
      next(); // Si el token es válido, continuar la navegación
    } else {
      next('/'); // Si el token no es válido, redirigir al login
    }
  } else {
    next(); // Si la ruta no requiere autenticación, continuar la navegación
  }
});

export default router;
