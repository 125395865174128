<!-- src/views/HomeView.vue -->
<template>
  <div class="home-container">
    <h1>Bienvenido al Panel de Administración</h1>
    <div class="navigation-links">
      <router-link to="/conversations" class="nav-link">Ir a Conversaciones</router-link>
      <router-link to="/crm" class="nav-link">Ir al CRM</router-link>
    </div>

    <!-- Versión del sitio -->
    <div class="version-info">
      Versión: {{ version }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data() {
    return {
      // Versión "hardcoded" de la página para pruebas
      version: '0.3.0',
    };
  },
};
</script>

<style scoped>
.home-container {
  text-align: center;
  padding: 50px;
}

.navigation-links {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.nav-link {
  padding: 10px 20px;
  background-color: #075e54;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.nav-link:hover {
  background-color: #064a45;
}

/* Estilo para la versión */
.version-info {
  font-size: 0.8em;
  color: #008000;
  text-align: center;
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
